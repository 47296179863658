<template>
  <div class="mx-6 ">
    <b-navbar
      :transparent="true"
      active.sync="true"
      class="navbar mx-6 my-5"
      id="navbar"
      :class="{ 'navbar--hidden mx-6 my-5': !showNavbar }"
    >
      <template slot="brand">
        <router-link :to="{ path: '/' }">
          <img
            width="200"
            :src="require('@/assets/FEC.png')"
            alt="FecMX"
            class="mx-1 mt-3"
          />
        </router-link>
      </template>
      <template slot="end">
        <b-navbar-item class="mx-4 " href="/">
          {{ $t("home") }}
        </b-navbar-item>
        <b-navbar-item class="mx-4" href="/services">
          {{ $t("services") }}
        </b-navbar-item>
        <b-navbar-item class="mx-4" href="/about">
          {{ $t("about") }}
        </b-navbar-item>
        <b-navbar-item class="mx-4" href="/apply-usa">
          apply usa
        </b-navbar-item>
        <b-navbar-item class="mx-4" href="/contact">
          {{ $t("contact") }}
        </b-navbar-item>
        <div class="level is-mobile mx-4">
          <div class="level-item pl-2">
            <b-navbar-item
              transparent
              v-on:click="switchLocale('en')"
              class="p-0 mx-2 is-size-7"
            >
              ENG
            </b-navbar-item>
            <b-navbar-item tag="label" class="py-1  is-size-7"
              >|</b-navbar-item
            >
            <b-navbar-item
              v-on:click="switchLocale('es')"
              class="p-0 is-size-7 mx-2"
            >
              ESP
            </b-navbar-item>
          </div>
          <b-navbar-item class="p-0 mr-5 is-hidden"><LocaleSwitcher /></b-navbar-item>
        </div>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";
export default {
  name: "Nav",
  components: { LocaleSwitcher },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    /*onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    }, */

    switchLocale(item) {
      this.$i18n.locale = item;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  border-radius: 35px 15px;
}

.navbar {
  background: rgba($color: black, $alpha: 0);
  opacity: 1;
  top: -15px;
  right: 0;
  left: 0;
  border-radius: 15px;
  position: fixed;
  .navbar-menu.is-active {
    border-radius: 50px 15px;
    background: rgba(255, 255, 255, 0.7);
    pointer-events: none;
  }
  transition: 0.1s all ease-out;
  box-shadow: none;
}

.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
.is-transparent {
  background-image: none;
}
</style>
